const DEV = process.env.NODE_ENV === 'development'

import Swiper from 'swiper'
import { Grid, Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'
import('@mux/mux-player').then(() => {
  onMuxPlayerLoaded()
})

function onMuxPlayerLoaded() {
  let live_player = document.getElementById('live-video-player')
  if (live_player && live_player.dataset.muxAutoplay === 'true') {
    live_player.play()
  }
}

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common')

    // Remove no-js
    document.documentElement.classList.remove('no-js')
    document.documentElement.classList.add('js')

    //live video language selection
    document.querySelectorAll('.panel-toggle').forEach((item) => {
      item.addEventListener('click', (event) => {
        item.classList.toggle('open')
      })
    })
    document.querySelectorAll('.panel-option').forEach((item) => {
      item.addEventListener('click', (event) => {
        if (item.dataset.stream && item.dataset.playerId) {
          const liveMuxPlayer = document.getElementById(item.dataset.playerId)
          if (liveMuxPlayer) {
            liveMuxPlayer.setAttribute('playback-id', item.dataset.stream)
            liveMuxPlayer.load()
            liveMuxPlayer.setAttribute('autoplay', 'true')
          }
        }
        const closestPanelToggle =
          item.parentElement.parentElement.querySelector('.panel-toggle')
        if (closestPanelToggle) {
          closestPanelToggle.querySelector('.current_selection').innerHTML =
            item.innerHTML
          closestPanelToggle.classList.remove('open')
        }
      })
    })

    function Marquee(selector, speed) {
      const parentSelector = selector
      const clone = parentSelector.innerHTML
      const firstElement = parentSelector.children[0]
      let i = 0
      console.log(firstElement)
      parentSelector.insertAdjacentHTML('beforeend', clone)
      parentSelector.insertAdjacentHTML('beforeend', clone)

      setInterval(function () {
        firstElement.style.marginLeft = `-${i}px`
        if (i > firstElement.clientWidth) {
          i = 0
        }
        i = i + speed
      }, 0)
    }
    //after window is completed load
    //1 class selector for marquee
    //2 marquee speed 0.2

    //window.addEventListener('load', function () {
    const marquees = document.querySelectorAll('.marquee')
    marquees.forEach((marquee) => {
      Marquee(marquee, 0.2)
    })
    // })
    // Swiper gallery
    // new Swiper('.swiper_partners', {
    //   modules: [Pagination, Autoplay, Navigation],
    //   speed: 600,
    //   slidesPerView: 1,
    //   spaceBetween: 20,
    //   loop: true,
    //   navigation: {
    //     nextEl: '.news_next',
    //     prevEl: '.news_prev',
    //   },
    //   pagination: {
    //     el: '.news_pagination',
    //     clickable: true,
    //   },
    //   autoplay: {
    //     delay: 2000,
    //   },
    //   // pagination: {
    //   //   el: '.gallery_pagination',
    //   //   clickable: true,
    //   // },
    // })

    //Swiper gallery
    new Swiper('.swiper_slideshow', {
      modules: [Pagination, Autoplay, Navigation],
      speed: 600,
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: '.ph_next',
        prevEl: '.ph_prev',
      },
      pagination: {
        el: '.news_pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },
      // pagination: {
      //   el: '.gallery_pagination',
      //   clickable: true,
      // },
    })

    // Test touch
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.classList.add('no-touch')
    } else {
      document.documentElement.classList.add('touch')
    }

    const loader = document.getElementById('loader')

    function hideLoader() {
      loader.classList.add('hide')
    }

    function showLoader() {
      loader.classList.add('show')
    }

    if (!DEV) {
      showLoader()
      setTimeout(function () {
        hideLoader()
      }, 2000)

      // window.addEventListener('load', function () {
      //   hideLoader()
      // })
    }

    // Show/hide mobile menu
    const hamburger = document.getElementById('hamburger')
    const body = document.body
    hamburger.addEventListener('click', function () {
      body.classList.toggle('show-menu')
    })

    // SHow/hide languages
    const btnLangs = document.querySelectorAll('.lang_switcher')
    const langs = document.querySelectorAll('.lang_box')
    btnLangs.forEach((btnLang, i) => {
      btnLang.addEventListener('click', function () {
        langs[i].classList.toggle('hidden')
      })
    })

    // Get first element with class .login
    // const login = document.querySelector('.login')
    // login.addEventListener('click', function () {
    //   MicroModal.show('login-modal')
    // })

    // If user logged in hide login button
    if (ajax_login_object.is_user_logged_in) {
      document.querySelector('.login').classList.add('hidden')
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('finalize common')
  },
}
